import "../css/landing.css"

import dmsImg from "../images/elements/dms.png"
import servicesImg1 from "../images/elements/services.png"
import servicesImg2 from "../images/elements/services-2.png"
import servicesImg3 from "../images/elements/services-3.png"
import notify from "./modules/notify"

jQuery(() => {
    $("img").each(function(index) {
        const arr = [dmsImg, servicesImg1, servicesImg2, servicesImg3]
        $(this).attr("src", arr[index])
    })
    
    const nav = $("#nav")
    const navActiveClass = "nav-show",
        navClosingClass = "nav-closing";

    const closeNav = () => {
        if (!nav.hasClass(navActiveClass)) return
        
        nav.addClass(navClosingClass)
        nav.on("animationend", function() {
            $(this).removeClass(navActiveClass)
            $(this).removeClass(navClosingClass)
            $(this).off("animationend")
        })
    }

    $(".landing__burger").on("click", () => {
        if (nav.hasClass(navActiveClass)) {
            closeNav()
        } else {
            nav.addClass(navActiveClass)
        }
    })
    
    $(".landing__link").on("click", function() {
        const section = $(this).attr("href").replace("#", "")

        const foundElement = $("section[category=" + section + "]")
        $(document.documentElement).animate({
            scrollTop: foundElement.offset().top - 100
        }, 500)

        closeNav()
    })

    const elements = $("section, .landing__utilities, .landing__message")
    elements.css("opacity", 0)

    const onScroll = () => {
        const header = $("header")
        const _header = $("#header-shadow")
        const scrollTop = $(window).scrollTop()
        if (scrollTop > 60) {
            header.addClass("header-fixed")
            _header.addClass("header-shadow")
        } else if (scrollTop <= 0) {
            header.removeClass("header-fixed")
            _header.removeClass("header-shadow")
        }

        elements.each(function() {
            if ((scrollTop + $(window).height() * 0.8) >= $(this).offset().top) {
                const specialClass = $(this).attr("anim")
                if (specialClass) {
                    $(this).addClass(specialClass)
                } else {
                    $(this).addClass("landing-showed")
                }
                $(this).animate({
                    opacity: 1,
                }, 250)
            }
        })
    }

    $(window).on("scroll", function() {
        onScroll()
    })
    $(window).on("touchmove", function() {
        onScroll()
    })

    $("#btn-signup").on("click", () => {
        notify.error("You can't sign up to updates at this moment, please try again later!")
    })

    $("#btn-access").on("click", () => {
        notify.error("Sorry, but we do not give access by request at this moment.")
    })

    onScroll()
})